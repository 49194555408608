import * as React from 'react';
import { Glyphicon, Nav, NavItem, Navbar, NavDropdown, MenuItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import './PrimaryNavigation.styles.scss';
import Logo from '../../branding/Logo.component';

export interface IPrimaryNavigationProps {
	isExpanded?: boolean,
}

const PrimaryNavigation: React.SFC<IPrimaryNavigationProps> = () => (
	<aside className='layout__sidebar'>
		<Navbar className='navbar--primary-navigation'>
			<Navbar.Header>
				<Navbar.Brand>
					<svg xmlns="http://www.w3.org/2000/svg" width="32" height="24" viewBox="0 0 24 16.751" fill="#FF696C" style={{marginLeft: 20, marginRight: 30}}>
						<g transform="translate(-191 -3173)">
							<g transform="translate(191 3173)">
								<path 
									d="M19.432,16.751H5.4A5.427,5.427,0,0,1,.424,13.436a5.387,5.387,0,0,1-.315-1.016A5.429,5.429,0,0,1,2.6,6.7a5.374,5.374,0,0,1,1.714-.677c0-.042,0-.086,0-.131A5.931,5.931,0,0,1,4.434,4.7,5.859,5.859,0,0,1,5.32,2.6,5.924,5.924,0,0,1,6.911,1.006a5.9,5.9,0,0,1,8.8,2.784A4.173,4.173,0,0,1,17.2,3.517a4.227,4.227,0,0,1,.844.085,4.164,4.164,0,0,1,1.5.63,4.2,4.2,0,0,1,1.517,1.843,4.167,4.167,0,0,1,.244.786,4.213,4.213,0,0,1,.085.844c0,.115,0,.23-.014.342a4.568,4.568,0,0,1-1.944,8.7ZM7.94,10.764A.436.436,0,0,0,7.5,11.2v1.382a.435.435,0,0,0,.435.435h8.136a.435.435,0,0,0,.435-.435V11.2a.436.436,0,0,0-.435-.435Zm0-3.15a.435.435,0,0,0-.435.435V9.431a.435.435,0,0,0,.435.435h8.136a.435.435,0,0,0,.435-.435V8.049a.435.435,0,0,0-.435-.435Z"
									transform="translate(0 0)" />
							</g>
							<g transform="translate(204.556 3184.468)">
								<ellipse cx="0.422" cy="0.422" rx="0.422" ry="0.422" transform="translate(0 0)" />
							</g>
							<g transform="translate(205.682 3184.468)">
								<ellipse cx="0.422" cy="0.422" rx="0.422" ry="0.422" transform="translate(0 0)" />
							</g>
							<g transform="translate(204.556 3181.318)">
								<ellipse cx="0.422" cy="0.422" rx="0.422" ry="0.422" transform="translate(0 0)" />
							</g>
							<g transform="translate(205.682 3181.318)">
								<ellipse cx="0.422" cy="0.422" rx="0.422" ry="0.422" transform="translate(0 0)" />
							</g>
						</g>
					</svg>
					<a href='/'>
						<span className='light'>nexVortex</span>
						<br/>
						Managed Connect Demo
					</a>
				</Navbar.Brand>
				<Navbar.Toggle>
					<Glyphicon glyph='cloud'/>
				</Navbar.Toggle>
			</Navbar.Header>
			<Navbar.Collapse>
				<Nav bsStyle='pills' stacked={true}>
					<NavDropdown
						className='open'
						eventKey={1}
						id='nv-edge'
						title={(
							<LinkContainer to='/nve'>
								<NavItem>
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16.003" viewBox="0 0 16 16.003" fill="#ffffff" style={{marginLeft: 27, marginRight: 40}}>
									<path
										d="M10.857,12.9C10.389,10.023,9.286,8,8,8s-2.388,2.023-2.855,4.9ZM4.9,16a20.223,20.223,0,0,0,.106,2.065h5.979A20.223,20.223,0,0,0,11.1,16a20.223,20.223,0,0,0-.106-2.065H5.011A20.223,20.223,0,0,0,4.9,16Zm10.476-3.1a8.019,8.019,0,0,0-5.1-4.569A10.89,10.89,0,0,1,11.9,12.9ZM5.717,8.336A8.013,8.013,0,0,0,.623,12.9H4.107a10.845,10.845,0,0,1,1.61-4.569Zm10.008,5.6h-3.7c.068.678.106,1.371.106,2.065s-.039,1.387-.106,2.065h3.7A7.933,7.933,0,0,0,16,16a8.071,8.071,0,0,0-.274-2.065ZM3.872,16c0-.694.039-1.387.106-2.065H.277a7.822,7.822,0,0,0,0,4.13h3.7C3.91,17.389,3.872,16.7,3.872,16Zm1.274,3.1C5.614,21.98,6.717,24,8,24s2.388-2.023,2.855-4.9Zm5.14,4.569a8.026,8.026,0,0,0,5.1-4.569H11.9A10.89,10.89,0,0,1,10.286,23.668ZM.623,19.1a8.019,8.019,0,0,0,5.1,4.569A10.89,10.89,0,0,1,4.107,19.1H.623Z"
										transform="translate(0 -8)" />
								</svg>
									Network Capabilities
								</NavItem>
							</LinkContainer>
						)}
					>
						<LinkContainer to='/nve/demo/overview'>
							<MenuItem>
								<Glyphicon glyph='simple-circle' />
								Voice Quality
							</MenuItem>
						</LinkContainer>
					</NavDropdown>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
		<Logo />
	</aside>
);

export default PrimaryNavigation;
