import * as React from 'react';

import Slider from '../../shared/fields/Slider.component';

export interface PacketLossInjectorProps {
	label: string,
	value: number,
	disabled?: boolean,
	onChange: (value: number) => void,
	onAfterChange?: (value: number) => void
}

export const PacketLossInjector: React.SFC<PacketLossInjectorProps> = ({
	label,
	value,
	disabled = false,
	onChange,
	onAfterChange
}) => (
	<React.Fragment>
		<label>{label}</label>
		<Slider
			min={0}
			max={75}
			step={0.01}
			value={value}
			defaultValue={0}
			marks={{
				0: '0%',
				75: '75%'
			}}
			disabled={disabled}
			onChange={onChange}
			onAfterChange={onAfterChange}
		/>
	</React.Fragment>
);

export default PacketLossInjector;
