import * as React from 'react';
import Context, { NotificationContext } from '../../../contexts/Notification.context';

export type NotificationComponentProps = NotificationContext;

export const withNotifications = <P extends NotificationComponentProps>(
	WrappedComponent: React.ComponentType<P>
) => (props: Pick<P, Exclude<keyof P, keyof NotificationComponentProps>>) => (
	<Context.Consumer>
		{context => (
			<WrappedComponent {...context} {...props as P}/>
		)}
	</Context.Consumer>
);

export default withNotifications;
