import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import runtimeConfig from './config';
import * as OApi from './utils/OnDemandApi';

import './App.scss';

import Authentication from './components/features/Authentication/Authentication.component';
import AuthRoute from './components/features/Authentication/AuthRoute.component';
import Notifications from './components/features/Notifications/Notifications.component';
import UserSession from './components/features/User/UserSession.component';

import { Layout } from './components/shared/Layout';
import { DemoDashboard, NotFound, Forbidden } from './components/features/Pages';
import User from './components/features/User/User.component';

class App extends React.Component {
	constructor(props: any) {
		super(props);

		OApi.config({
			baseUrl: runtimeConfig.OAPI_BASE_URL || '',
			clientId: runtimeConfig.OAPI_CLIENT_ID || '',
			clientSecret: runtimeConfig.OAPI_CLIENT_SECRET || ''
		});
	}

	public render() {
		return (
			<User>
				<Authentication>
					<Notifications>
						<Switch>
							<Route path='/error/404' exact={true} component={NotFound}/>
							<Route path='/error/403' exact={true} component={Forbidden}/>
							<AuthRoute
								redirectPath='/error/403'
								render={() => (
									<UserSession onError={() => { /* NOTE: Should redirect to some page */ }}>
										<div className='app'>
											<Layout>
												<Switch>
													<Redirect from='/' exact={true} to='/nve/demo/overview'/>
													<Redirect from='/nve' exact={true} to='/nve/demo/overview'/>
													<Route
														path='/nve/demo/overview'
														component={() => (
															<DemoDashboard title='Managed Connect - Voice Quality' className='page_demo-dashboard'/>
														)}
													/>
													<Route
														path='/nve/demo/configuration'
														component={() => (
															<DemoDashboard title='Managed Connect - Voice Quality' className='page_demo-dashboard'/>
														)}
													/>
													<Route path='/error/404' exact={true} component={NotFound}/>
													<Redirect to='/error/404'/>
												</Switch>
											</Layout>
										</div>
									</UserSession>
								)}
							/>
						</Switch>
					</Notifications>
				</Authentication>
			</User>
		);
	}
}

export default App;
