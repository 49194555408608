import * as React from 'react';

export interface SessionProps {
	loader: React.ReactNode,
	onError: (error?: Error) => void,
	createSession?: () => void | Promise<void>,
	destroySession?: () => void | Promise<void>
}

interface SessionState {
	bootstrapped: boolean
}

export default class Session extends React.Component<SessionProps, SessionState> {
	public state = {
		/** If true, the application session was built successfully after component mount */
		bootstrapped: false
	};

	public async componentDidMount() {
		// Create session
		if (!this.props.createSession) {
			console.debug('Session.props.createSession does not exist');
			this.setState({ bootstrapped: true });
			return;
		}

		try {
			console.debug('Creating session...');
			await this.props.createSession();
			this.setState({ bootstrapped: true }, () => console.debug('Session created'));
		}
		catch (error) {
			this.props.onError(error);
		}
	}

	public async componentWillUnmount() {
		// Destroy session
		if (!this.props.destroySession) {
			console.debug('Session.props.destroySession does not exist');
			this.setState({ bootstrapped: false });
			return;
		}

		try {
			console.debug('Destroying session...');
			await this.props.destroySession();
			this.setState({ bootstrapped: false }, () => console.debug('Session destroyed'));
		}
		catch (error) {
			this.props.onError(error);
		}
	}

	public render() {
		return !this.state.bootstrapped ? (
			this.props.loader
		) : (
			this.props.children
		);
	}
}
