import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import './DemoDashboard.styles.scss';

import withPage from '../../../shared/Layout/Page/withPage';
import NetworkStatus from '../../NetworkStatus/NetworkStatus.component';
import { Interface } from '../../InterfaceStatus/InterfaceStatus.component';
import Configuration from '../../Configuration/Configuration.component';
import EdgeDevice, { PathConfiguration } from '../../EdgeDevice/EdgeDevice.component';
import { HttpForbidden, NetworkException } from '../../../shared/http';
import { Loading } from '../../../shared/icons';
import NotificationContext, {
	NotificationContext as NotificationContextType,
	Notification
} from '../../../../contexts/Notification.context';
import withNotifications, { NotificationComponentProps } from '../../Notifications/withNotifications';
import { Path, DeviceState } from '../../../../utils/OnDemandApi/EdgeDevices';
import DeviceInfo from '../../DeviceInfo/DeviceInfo.component';

// const convertBytesToKilobits = (bytes: number) => ((bytes * 8) / 1000).toFixed(2);
// const convertRatioToPercentage = (ratio: number) => (ratio * 100).toFixed(2);

export interface IDemoDashboardProps
	extends React.HTMLAttributes<HTMLElement>, RouteComponentProps, NotificationComponentProps
{
	title: string
}

interface DemoDashboardState {
	interfacePacketLoss: { [name: string]: number }
}

class DemoDashboard extends React.Component<IDemoDashboardProps, DemoDashboardState> {
	public static contextType = NotificationContext;

	public state = {
		interfacePacketLoss: {}
	};

	// private updatePacketLoss = (value: number, index: number) =>
	// 	this.setState(prevState => ({
	// 		interfacePacketLoss: [
	// 			...prevState.interfacePacketLoss.slice(0, index),
	// 			value,
	// 			...prevState.interfacePacketLoss.slice(index + 1)
	// 		]
	// 	}))

	private notifyError = (error: any, title?: string) => {
		console.error(error);

		let notification: Notification = {
			type: 'error',
			title: title || 'Something went wrong',
			message: error.message || ''
		};

		if (error instanceof NetworkException) {
			notification = {
				...notification,
				title: 'Unable to reach network',
			};
		}
		// NOTE: A custom error type should be used for this, but we're cheating for now
		else if (error.message === 'Your session has expired') {
			notification = {
				...notification,
				type: 'warn',
				title: error.message,
				message: ''
			};
		}

		const { add } = this.context as NotificationContextType;
		add(notification);
	}

	private getCpuId = (): string | null => {
		const params = new URLSearchParams(this.props.location.search);
		return params.get('cpuId');
	}

	private getInterfaces = (device: DeviceState): Array<{
		name: string,
		ip: string,
		port: number,
		paths: Path[]
	}> => {
		const { fullReport: { clientQuality: { links = [] } } } = device;

		// Map paths to collections organized by interface name
		const interfaceMap = links.reduce<{ [name: string]: Path[] }>(
			(acc, path) => {
				const prevPaths = acc[path.receiverInterface] || [];
				return ({
					...acc,
					// Paths are sorted as highest cost first
					[path.receiverInterface]: [ ...prevPaths, path ]
				});
			},
			{}
		);

		return Object.entries(interfaceMap)
			.map(([ key, paths ]) => ({
				name: key,
				ip: paths[0].receiverIP,
				port: paths[0].receiverPort,
				paths
			}))
			// Sort by name, alphabetically
			.sort((a, b) => {
				if(a.name < b.name) { return -1; }
				if(a.name > b.name) { return 1; }
				return 0;
			});
	}

	public componentDidCatch(error: any) {
		const { history } = this.props;

		if (error instanceof HttpForbidden) {
			history.replace('/error/403');
			return null;
		}

		if (error instanceof HttpForbidden) {
			history.replace('/error/404');
			return null;
		}

		this.notifyError(error);

		return (
			<p>{error.message}</p>
		);
	}

	public componentDidMount() {
		const deviceCpuId = this.getCpuId();

		if (!deviceCpuId) {
			const notification: Notification = {
				type: 'error',
				title: 'Edge Device CPU ID Not Found',
				message: 'The edge device CPU ID must be specified as part of the URL'
			};

			const { add } = this.context as NotificationContextType;
			add(notification);
		}
	}

	public render() {
		const deviceCpuId = this.getCpuId();

		if (!deviceCpuId) {
			return null;
		}

		return (
			<EdgeDevice
				id={deviceCpuId}
				onError={this.notifyError}
			>
				{({ device, pathConfig, updatePathConfig, injectPacketLoss }) => {
					if (pathConfig === null || device === null) {
						return <Loading/>;
					}

					const interfaces = this.getInterfaces(device);

					if (interfaces.length > 2) {
						throw new TypeError('Demo only supports two interface devices');
					}

					return (
						<React.Fragment>
							<NetworkStatus pathConfig={pathConfig} interfaces={interfaces}/>

							<Configuration
								initialPathConfig={pathConfig}
								pathConfigOptions={[
									{ label: 'Two Wired', value: PathConfiguration.TwoWired },
									{ label: 'Wired with LTE', value: PathConfiguration.TwoWiredLTE }
								]}
								onSubmitPathConfig={async value => {
									console.debug(`PathConfiguration request to change to ${value ? (PathConfiguration[value] || value) : value}`);
									if (value !== null) {
										console.debug('Updating device path configuration...');
										await updatePathConfig(value);
										console.debug(`Edge device path configuration set to ${PathConfiguration[value]}`);
									}
								}}
								interfaces={interfaces}
								injectPacketLoss={injectPacketLoss}
							/>

							{interfaces.map(deviceInterface => (
								<Interface
									key={deviceInterface.name}
									{...deviceInterface}
								/>
							))}

							<DeviceInfo device={device}/>
						</React.Fragment>
					);
				}}
			</EdgeDevice>
		);
	}
}

export default withPage(withRouter(withNotifications(DemoDashboard)));
