import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';

import AuthContext from '../../../contexts/Authentication.context';

const hasNoChildren = (children: any) => React.Children.count(children) === 0;

export interface AuthRouteProps extends RouteProps {
	redirectPath: string
}

export const AuthRoute: React.SFC<AuthRouteProps> = ({
	redirectPath,
	component,
	render,
	children,
	...rest
}) => (
	<Route
		{...rest}
		render={routeComponentProps => (
			<AuthContext.Consumer>
				{({ authenticated }) => {
					if (authenticated) {
						console.debug('Rendering authenticated route');
						// Modified copy of render logic in <Route>
						return (children && !hasNoChildren(children))
							? children
							: component
								? React.createElement(component, routeComponentProps)
								: render
									? render(routeComponentProps)
									: null;
					}
					else {
						console.debug('Redirecting to 403 route');
						return (
							<Redirect to={redirectPath}/>
						);
					}
				}}
			</AuthContext.Consumer>
		)}
	/>
);

export default AuthRoute;
