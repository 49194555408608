import {
	BASE_URL,
	buildHeaders
} from './Client';

type DeviceDateTime = string;
type DeviceStatus = 'active';
type IPAddress = string;
type Port = number;

interface DevicePath {
	nVEInterface: string,
	sdngwInterface: string,
	nVEIP: IPAddress,
	sdngwIP: IPAddress,
	nVEPort: Port,
	sdngwPort: Port
}

export interface Path {
	/** If cost > 5 then connection is LTE, else connection is Wired */
	cost: number,
	senderInterface: string,
	senderIP: IPAddress,
	receiverInterface: string,
	receiverIP: IPAddress,
	receiverPort: Port,
	lossRatio: number,
	packetsSent: number,
	packetsReceived: number,
	originalPacketsRecieved: number,
	dataReceived: number,
	packetsLost: number,
	packetsRate: number,
	timeInterval: number,
	roundTripTime: number,
	active: boolean,
	other: any[]
}

interface QualityReport {
	escalationCost: number,
	lossRatio: number,
	packetsSent: number,
	packetsReceived: number,
	originalPacketsRecieved: number,
	dataReceived: number,
	packetsLost: number,
	packetsRate: number,
	timeInterval: number,
	roundTripTime: number,
	links: Path[],
	other: []
}

interface DeviceReport {
	cpuId: string,
	nvsbTunnelRecId: number,
	sdngwNodeName: string,
	sdngwHostName: string,
	timestamp: DeviceDateTime,
	activeCalls: number,
	packing: boolean,
	escalationCost: number,
	maxPathCost: number,
	icmpPings: [],
	other: [],
	paths: DevicePath[],
	gatewayQuality: QualityReport,
	clientQuality: QualityReport
}

export interface DeviceState {
	nvsbTunnelRecid: number,
	createDateTime: DeviceDateTime,
	updateDateTime: DeviceDateTime,
	eventTimestamp: DeviceDateTime,
	status: DeviceStatus,
	serverPacketsSent: number,
	serverPacketsReceived: number,
	clientPacketsSent: number,
	clientPacketsReceived: number,
	numberOfCalls: number,
	fullReport: DeviceReport
}

export interface EdgeDeviceState {
	data: DeviceState[],
	meta: {
		pagination: {
			total: number,
			count: number,
			per_page: number,
			current_page: number,
			total_pages: number,
			links: []
		}
	}
}

/** Get device quality report */
export const get = (token: string, id: string) => {
	if (!token || !(token.length > 0)) {
		throw new TypeError('Client token is required.');
	}

	if (!id || !(id.length > 0)) {
		throw new TypeError('Device ID is required.');
	}

	const url = `${BASE_URL}/v2/mHV/Inventory/EdgeDevices/${id}/Quality/State`;

	return new Request(url, {
		headers: buildHeaders(token),
		method: 'GET'
	});
};

interface InjectTunnelPacketLossPayload {
	action: 'inject_tunnel_packet_loss',
	parameters: {
		sdngwIntName: string,
		nVEIntName: string,
		loss: number // ratio, 0. - 1.
	}
}

/**
 * Inject simulated path packet loss for the device
 *
 * Returns a string as the response body on successful requests, not JSON.
 */
export const injectTunnelPacketLoss = (
	/** API authorization token */
	token: string,
	/** Edge device CPU ID */
	id: string,
	/** Name of SDN gateway target interface */
	gatewayName: string,
	/** Name of edge device target interface */
	interfaceName: string,
	/** Packet loss ratio to inject, 0. - 1. */
	ratio: number
) => {
	if (!token || !(token.length > 0)) {
		throw new TypeError('Client token is required.');
	}

	if (!id || !(id.length > 0)) {
		throw new TypeError('Device ID is required.');
	}

	const url = `${BASE_URL}/v2/mHV/Inventory/EdgeDevices/${id}/Actions`;
	const config: InjectTunnelPacketLossPayload = {
		action: 'inject_tunnel_packet_loss',
		parameters: {
			sdngwIntName: gatewayName,
			nVEIntName: interfaceName,
			loss: ratio
		}
	};

	return new Request(url, {
		headers: buildHeaders(token),
		method: 'POST',
		body: JSON.stringify(config)
	});
};

interface UpdatePathConfigPayload {
	action: 'update_path_configuration',
	parameters: {
		pathConfigId: number
	}
}

/** Update the device path configuration */
export const updatePathConfiguration = (
	/** API authorization token */
	token: string,
	/** Edge device CPU ID */
	id: string,
	/** Edge device pathyway configuration ID */
	configId: number
) => {
	if (!token || !(token.length > 0)) {
		throw new TypeError('Client token is required.');
	}

	if (!id || !(id.length > 0)) {
		throw new TypeError('Device ID is required.');
	}

	const url = `${BASE_URL}/v2/mHV/Inventory/EdgeDevices/${id}/Actions`;
	const config: UpdatePathConfigPayload = {
		action: 'update_path_configuration',
		parameters: {
			pathConfigId: configId
		}
	};

	return new Request(url, {
		headers: buildHeaders(token),
		method: 'POST',
		body: JSON.stringify(config)
	});
};

export interface DeviceTunnel {
	nvsbTunnelRecid: number,
	createDateTime: string,
	updateDateTime: string,
	status: string,
	nvsbSdngwRecid: number,
	nvsbDeviceRecid: number,
	tunnelId: number,
	tunnelAddress: number,
	kIpAddress: IPAddress,
	cIntName: string,
	dIntName: string,
	serverTunnelHeaderVsn: number,
	qualityLogInterval: number,
	logToFile: number,
	commandHeaderVsn: number,
	pathConfigVersion: number
}

export interface EdgeDeviceTunnel {
	data: DeviceTunnel[]
}
export const tunnel = (token: string, id: string) => {
	if (!token || !(token.length > 0)) {
		throw new TypeError('Client token is required.');
	}

	if (!id || !(id.length > 0)) {
		throw new TypeError('Device ID is required.');
	}

	const url = `${BASE_URL}/v2/mHV/Inventory/EdgeDevices/${id}/Tunnel`;

	return new Request(url, {
		headers: buildHeaders(token),
		method: 'GET'
	});
};

export interface Healthcheck {
	time: number,
	status: string,
	message: string,
	lte: Array<{
		tags: string[],
		override: boolean,
		id: string,
		address: IPAddress
	}>,
	firmware: string,
	destination: IPAddress,
	addresses: IPAddress[]
}

export interface EmsHealthcheck {
	id: string,
	count: number,
	start: number,
	data: Healthcheck
}

/** Check EMS health via ping */
export const health = (token: string, id: string) => {
	if (!token || !(token.length > 0)) {
		throw new TypeError('Client token is required.');
	}

	if (!id || !(id.length > 0)) {
		throw new TypeError('Device ID is required.');
	}

	const url = `${BASE_URL}/Assets/nVE/${id}/actions/healthcheck`;

	return new Request(url, {
		headers: buildHeaders(token),
		method: 'POST'
	});
};

export const EdgeDevices = {
	get,
	injectTunnelPacketLoss,
	updatePathConfiguration,
	tunnel,
	health
};

export default EdgeDevices;
