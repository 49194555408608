import * as React from 'react';

import Context, {
	NotificationContext,
	Notification as NotificationProps,
	defaultValue
} from '../../../contexts/Notification.context';

import Notification from './Notification.component';

export default class Notifications extends React.Component<{}, NotificationContext> {
	constructor(props: {}) {
		super(props);

		this.state = {
			...defaultValue,
			show: this.show,
			add: this.add,
			close: this.close,
			clear: this.clear
		};
	}

	private show = () => {
		this.setState(
			prevState => ({ ...prevState, visible: true }),
			() => { setTimeout(() => this.close(), 5000); }
		);
	}

	// NOTE: Purposely only supports holding a single notification in state
	private add = (notification: NotificationProps) => this.setState(prevState => ({
		...prevState,
		notifications: [ notification ]
	}))

	private close = () => this.setState(prevState => ({
		...prevState,
		notifications: prevState.notifications.slice(1)
	}))

	private clear = () => this.setState(prevState => ({
		...prevState,
		notifications: []
	}))

	public render() {
		return (
			<Context.Provider value={this.state}>
				{this.props.children}
				<aside className='notifications'>
					{this.state.notifications.map((notification, index) => (
						<Notification key={index} {...notification}/>
					))}
				</aside>
			</Context.Provider>
		);
	}
}
