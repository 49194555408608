import * as React from 'react';
import RcSlider, { SliderProps as RcSliderProps } from 'rc-slider';
import 'rc-slider/assets/index.css';

export const Slider: React.SFC<RcSliderProps> = ({
	min,
	max,
	step,
	value,
	marks,
	defaultValue = min,
	disabled = false,
	...rest
}) => (
	<RcSlider
		{...rest}
		value={value}
		defaultValue={defaultValue}
		min={min}
		max={max}
		step={step}
		disabled={disabled}
		marks={marks}
		dots={false}
		railStyle={{
			height: '8px',
			background: '#cbcccd'
		}}
		trackStyle={{
			height: '8px',
			background: '#bc585a'
		}}
		handleStyle={{
			width: '18px',
			height: '18px',
			background: '#333333',
			borderColor: '#333333'
		}}
		dotStyle={{
			display: 'none'
		}}
	/>
);

export default Slider;
