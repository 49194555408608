import * as React from 'react';
import Modal from 'react-modal';

import Select from '../../shared/fields/Select.component';
import Button from '../../shared/fields/Button.component';
import { PathConfiguration } from '../EdgeDevice/EdgeDevice.component';
import './Configuration.scss';
import { DeviceInterface } from '../InterfaceStatus/InterfaceStatus.component';
import PacketLossInjector from '../InterfaceStatus/PacketLossInjector.component';

export interface ConfigurationProps {
	interfaces: DeviceInterface[]
	initialPathConfig: PathConfiguration | null,
	pathConfigOptions: Array<{ label: string, value: PathConfiguration }>,
	onSubmitPathConfig: (pathConfig: PathConfiguration | null) => void,

	injectPacketLoss: (gatewayName: string, interfaceName: string, ratio: number) => Promise<void>
}

interface FormState {
	selected: PathConfiguration | null,
	simulatedPacketLoss: { [interfaceName: string]: number }
}

export interface ConfigurationState extends FormState {
	open: boolean
}

export default class Configuration extends React.Component<ConfigurationProps, ConfigurationState> {
	public static defaultProps = {
		options: [],
		interfaces: []
	};

	public state = {
		open: false,
		selected: this.props.initialPathConfig || null,
		simulatedPacketLoss: this.props.interfaces.reduce<{ [interfaceName: string]: number}>(
			(state, deviceInterface) => ({ ...state, [deviceInterface.name]: 0 }),
			{}
		)
	};

	private handleChange = (selected: PathConfiguration) => {
		this.setState({ selected });
	}

	private handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		this.props.onSubmitPathConfig(this.state.selected);
		this.setState({
			simulatedPacketLoss: this.props.interfaces.reduce<{ [interfaceName: string]: number}>(
				(state, deviceInterface) => ({ ...state, [deviceInterface.name]: 0 }),
				{}
			)
		});
	}

	private open = () => this.setState({ open: true });

	private close = () => this.setState({ open: false });

	private updatePacketLossState = (deviceInterface: DeviceInterface, value: number) =>
		this.setState(prevState => ({
			...prevState,
			simulatedPacketLoss: {
				...prevState.simulatedPacketLoss,
				[deviceInterface.name]: value
			}
		}))

	private updateInterfacePacketLoss = async (deviceInterface: DeviceInterface, value: number) => {
		const ratio = value / 100;
		console.debug(`Setting simulated packet loss to ${ratio}% for all paths for ${deviceInterface.name}...`);
		await Promise.all(deviceInterface.paths.map(
			path => this.props.injectPacketLoss(path.senderInterface, path.receiverInterface, ratio)
		));
		console.debug(`${deviceInterface.name} simulated packet loss updated`);
	}

	public render() {
		const { interfaces, pathConfigOptions, initialPathConfig } = this.props;

		return (
			<section className='demo-configuration'>
				<Button onClick={this.open}>Configure</Button>
				<Modal
					isOpen={this.state.open}
					onRequestClose={this.close}
					className='config-modal'
					overlayClassName='config-overlay'
				>
					<form id='device-configuration' onSubmit={this.handleSubmit}>
						<h3>Configure</h3>
						<button type='button' data-action='close' onClick={this.close}>
							<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 13'>
								<polyline className='arrow' fill='none' stroke='currentColor' points='1 1,6.5 6.5,12 1'/>
								<polyline className='arrow' fill='none' stroke='currentColor' points='1 12,6.5 6.5,12 12'/>
							</svg>
							<span>Close</span>
						</button>
						<p>Choose your desired configuration and click the button below to begin the demo</p>
						<section>
							<Select
								defaultSelection={initialPathConfig}
								label='Path Configuration'
								selected={this.state.selected}
								emptyValue='Please select'
								options={pathConfigOptions}
								onChange={this.handleChange}
							/>
							<Button>Update Path Configuration</Button>
						</section>
						<section>
							<h4>Simulated Packet Loss</h4>
							{interfaces.map(deviceInterface => (
								<PacketLossInjector
									key={deviceInterface.name}
									label={deviceInterface.name}
									value={this.state.simulatedPacketLoss[deviceInterface.name]}
									onChange={value => this.updatePacketLossState(deviceInterface, value)}
									onAfterChange={value => this.updateInterfacePacketLoss(deviceInterface, value)}
								/>
							))}
						</section>
					</form>
				</Modal>
			</section>
		);
	}
}
