import * as React from 'react';

import { EdgeDevice, Firewall, Phone, CloudGreen, CloudRed, CloudLte } from '../../shared/icons';
import './NetworkStatus.scss';
import { Path } from '../../../utils/OnDemandApi/EdgeDevices';
import { PathConfiguration } from '../EdgeDevice/EdgeDevice.component';

const Connection: React.SFC<{ paths: Path[] }>= ({ paths }) => {
	// Paths provided are ordered as highest cost first
	const reachablePaths = paths.filter(path => path.roundTripTime > -1);

	// If no paths are active, or if all paths are unreachable, then interface as unable to connect
	if (!(reachablePaths.length > 0)) {
		return (
			<CloudRed className='internet-connection' data-active={false}/>
		);
	}

	// Check highest-cost, reachable path to render as LTE or standard connnection
	const ltePaths = reachablePaths.filter(path => path.cost > 5);

	if (ltePaths.length > 0) {
		return (
			<CloudLte className='internet-connection' data-active={false} data-lte={true}/>
		);
	}
	else {
		return (
			<CloudGreen className='internet-connection' data-active={true}/>
		);
	}
};

const Pathway: React.SFC<{ width?: number, height?: number }> = props => (
	<svg
		version='1.1'
		className='network-pathway'
		x='0'
		y='0'
		viewBox='0 0 72 8'
		enableBackground='new 0 0 72 8'
		{...props}
	>
		<g className='dots'>
			<circle className='dot' cx='4' cy='4' r='4'/>
			<circle className='dot' cx='36' cy='4' r='4'/>
			<circle className='dot' cx='68' cy='4' r='4'/>
		</g>
	</svg>
);

const pathConfigName = (pathConfig: PathConfiguration): string => {
	const lookup = {
		[PathConfiguration.TwoWired]: 'Two Wired',
		[PathConfiguration.TwoWiredLTE]: 'Wired with LTE'
	};

	return lookup[pathConfig] || 'Unknown';
};

interface NetworkStatusProps {
	pathConfig: PathConfiguration,
	interfaces: Array<{
		name: string,
		ip: string,
		port: number,
		paths: Path[]
	}>
}

export class NetworkStatus extends React.Component<NetworkStatusProps> {
	public render() {
		const { pathConfig, interfaces } = this.props;
		return (
			<article className='network-status'>
				<h3>{pathConfigName(pathConfig)}</h3>
				<ol>
					<li className='node'>
						<Phone className='phone' width={undefined} height={undefined}/>
						<span>Phone</span>
					</li>
					<li className='path'><Pathway/></li>
					<li className='node'>
						<Firewall className='firewall'/>
						<span>Firewall</span>
					</li>
					<li className='path'><Pathway/></li>
					<li className='node'>
						<EdgeDevice className='edge-device'/>
						<span>nVE Device</span>
					</li>
					<li className='path'><Pathway/></li>
					<li className='internet'>
						<dl>
							{interfaces.map(({ name, paths }) => (
								<React.Fragment key={name}>
									<dt>{name}</dt>
									<dd><Connection paths={paths}/></dd>
								</React.Fragment>
							))}
						</dl>
					</li>
				</ol>
			</article>
		);
	}
}

export default NetworkStatus;
