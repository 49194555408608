import * as React from 'react';
import { Navbar, NavbarProps } from 'react-bootstrap';

import './Header.styles.scss';
import { UserNavigation } from '../Navigation';
import { SiteSearch } from '../Search';

const Header: React.SFC<NavbarProps> = props => (
	<header className='header header--global'>
		<Navbar
			className='navbar--global'
			collapseOnSelect={true}
			fluid={true}
			fixedTop={false}
			{...props}
		>
			<Navbar.Header>
				<Navbar.Brand>
					<span>nexVortex</span> Managed Connect
				</Navbar.Brand>
				<Navbar.Toggle/>
			</Navbar.Header>
			<Navbar.Collapse>
				<SiteSearch/>
				<UserNavigation/>
			</Navbar.Collapse>
		</Navbar>
	</header>
);

export default Header;
