import * as React from 'react';
import { NavLink, RouteComponentProps } from 'react-router-dom';

import AuthContext, { AuthenticationContext } from '../../../../contexts/Authentication.context';
import './Forbidden.scss';

export default class Forbidden extends React.Component<RouteComponentProps> {
	public static contextType = AuthContext;

	public componentDidMount() {
		const { authenticated } = this.context as AuthenticationContext;

		if (authenticated) {
			this.props.history.replace('/');
		}
	}

	public render() {
		return (
			<main id='forbidden'>
				<h2>You are not authroized to access this page</h2>
				<NavLink to={{ pathname: '/', search: this.props.location.search }}>Take me back</NavLink>
			</main>
		);
	}
}
