export let BASE_URL = '';
export let CLIENT_SECRET = '';
export let CLIENT_ID = '';

export interface ClientConfig {
	baseUrl: string,
	clientId: string,
	clientSecret: string
}

export const config = ({ baseUrl, clientId, clientSecret }: ClientConfig): void => {
	BASE_URL = baseUrl;
	CLIENT_ID = clientId;
	CLIENT_SECRET = clientSecret;
};

/** Checks if the token argument is an empty string */
export const requireToken = (token: string): void => {
	if (!token) {
		throw new TypeError('Token is required');
	}
};

export const HEADERS = Object.freeze({
	Accept: 'application/json',
	'Content-Type': 'application/json'
});

/** Returns a set of Headers for accessing JSON endpoints */
export const buildHeaders = (token: string) =>
	new Headers({
		...HEADERS,
		Authorization: `Bearer ${token}`
	});

export const encodeBody = (data: any) => JSON.stringify(data);
