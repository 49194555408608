import * as React from 'react';
import { Navbar, FormGroup, FormControl, Glyphicon, InputGroup } from 'react-bootstrap';

import './SiteSearch.styles.scss';

const SiteSearch: React.SFC = () => (
	<Navbar.Form className='navbar-form--site-search' pullLeft={true}>
		<FormGroup className='form-group--site-search'>
			<InputGroup>
				<InputGroup.Addon>
					<Glyphicon glyph='search'/>
				</InputGroup.Addon>
				<FormControl type='text' placeholder='Search this site...'/>
			</InputGroup>
		</FormGroup>
	</Navbar.Form>
);

export default SiteSearch;
