import * as React from 'react';

export const Logo: React.SFC<{ width?: string, height?: string }> = ({ width, height }) => (
	<svg version="1.1" width={width} height={height} viewBox="0 0 112 46.537">
		<title>nexVortex</title>
		<g transform="translate(0 18.479)">
			<path d="M0,155.011V144.7H2.683L7.628,151v-6.3H10.3v10.311H7.628l-4.945-6.3v6.3Z" transform="translate(0 -144.7)"/>
			<path d="M103.365,147.062h-3.181v1.725h3v2.274h-3v1.776h3.181v2.274H97.5V144.8h5.865Z" transform="translate(-85.043 -144.787)"/>
			<path d="M154.025,149.517l-3.207-4.817h3.309l1.393,2.67,1.725-2.67h3.309l-3.4,4.817L161,155.011H157.73l-2.07-3.36-2.415,3.36H150Z" transform="translate(-130.835 -144.7)"/>
		</g>
		<g transform="translate(45.665 18.211)">
			<path d="M357.4,148.03a5.344,5.344,0,0,1,.422-2.134A5.235,5.235,0,0,1,359,144.172a5.067,5.067,0,0,1,1.8-1.15,6.483,6.483,0,0,1,4.6,0,5.638,5.638,0,0,1,1.814,1.15A5.551,5.551,0,0,1,368.4,145.9a5.609,5.609,0,0,1,0,4.267,5.082,5.082,0,0,1-1.188,1.725,5.429,5.429,0,0,1-1.814,1.15,6.137,6.137,0,0,1-2.3.422,6.043,6.043,0,0,1-2.3-.422,5.578,5.578,0,0,1-1.8-1.15,5.513,5.513,0,0,1-1.175-1.725A5.434,5.434,0,0,1,357.4,148.03Zm2.8,0a2.783,2.783,0,0,0,.23,1.137,3.027,3.027,0,0,0,.626.907,3.178,3.178,0,0,0,.92.6,2.97,2.97,0,0,0,1.112.217,2.921,2.921,0,0,0,1.112-.217,2.992,2.992,0,0,0,.933-.6,2.712,2.712,0,0,0,.639-.907,2.926,2.926,0,0,0,0-2.274,2.847,2.847,0,0,0-.639-.907,3.078,3.078,0,0,0-.933-.6,2.97,2.97,0,0,0-1.112-.217,2.921,2.921,0,0,0-1.112.217,2.851,2.851,0,0,0-1.546,1.508A2.74,2.74,0,0,0,360.2,148.03Z" transform="translate(-357.4 -142.6)"/>
			<path d="M468.473,155.011h-3.335l-2.555-3.961v3.961H459.9V144.7h4.165a4.186,4.186,0,0,1,1.508.256,2.818,2.818,0,0,1,1.687,1.7,3.538,3.538,0,0,1,.217,1.227,3.064,3.064,0,0,1-.562,1.9,2.908,2.908,0,0,1-1.674,1Zm-5.89-5.7h.511a1.96,1.96,0,0,0,1.214-.332,1.271,1.271,0,0,0,0-1.891,1.96,1.96,0,0,0-1.214-.332h-.511Z" transform="translate(-446.804 -144.432)"/>
			<path d="M533.181,146.974v8.037H530.5v-8.037h-2.2V144.7h7.078v2.274Z" transform="translate(-506.464 -144.432)"/>
			<path d="M598.865,147.062h-3.181v1.725h3v2.274h-3v1.776h3.181v2.274H593V144.8h5.865Z" transform="translate(-562.898 -144.519)"/>
			<path d="M649.537,149.517l-3.207-4.817h3.309l1.393,2.67,1.725-2.67h3.309l-3.4,4.817,3.846,5.494H653.23l-2.07-3.36-2.415,3.36H645.5Z" transform="translate(-608.69 -144.432)"/>
		</g>
		<g transform="translate(29.668 13.305)">
			<path d="M232.2,104.2h3.731l5.149,14.412h.1L246.4,104.2h3.59l-7.513,18.718h-2.888Z" transform="translate(-232.2 -104.2)"/>
		</g>
		<g transform="translate(73.25 0.053)">
			<path d="M604.412,12.525A25.1,25.1,0,0,0,590.293,1.511a28.664,28.664,0,0,0-15.1-.026,1.021,1.021,0,1,0,.5,1.98,26.876,26.876,0,0,1,14.016,0,23.156,23.156,0,0,1,12.981,10.17c2.709,4.2,3.156,10.388,1.112,15.754a16.638,16.638,0,0,1-11.256,10.426A14.85,14.85,0,0,1,580.008,37.7a12.03,12.03,0,0,1-4.587-6.2H573.3a13.925,13.925,0,0,0,5.52,7.858,16.675,16.675,0,0,0,9.7,3.054,17.039,17.039,0,0,0,4.587-.626,18.617,18.617,0,0,0,12.624-11.678C607.989,24.152,607.466,17.239,604.412,12.525Z" transform="translate(-573.3 -0.487)"/>
		</g>
		<g transform="translate(68.587 0)">
			<path d="M563.519,85.272a12.04,12.04,0,0,1,1.827-1.61c4.983-3.514,12.023-3.233,15.371.6a1.023,1.023,0,1,0,1.546-1.342,11.691,11.691,0,0,0-8.586-3.769,15.879,15.879,0,0,0-9.493,2.836,14.052,14.052,0,0,0-3.284,3.284h2.619Z" transform="translate(-557.821 -69.042)"/>
			<path d="M579.424,15.763A35.955,35.955,0,0,0,570.685.393a1.022,1.022,0,1,0-1.495,1.393,34.017,34.017,0,0,1,8.254,14.489c1.661,6.682.434,13.262-3.641,19.523a20.029,20.029,0,0,1-15.358,8.7,20.266,20.266,0,0,1-8.637-1.227,19.107,19.107,0,0,1-7.6-5.29,15.027,15.027,0,0,1-3.322-6.848H536.8a17.134,17.134,0,0,0,3.871,8.2,21.325,21.325,0,0,0,8.407,5.852,22.224,22.224,0,0,0,7.845,1.418c.562,0,1.112-.026,1.674-.064a22.057,22.057,0,0,0,16.917-9.621C579.922,30.138,581.238,23.034,579.424,15.763Z" transform="translate(-536.8 -0.07)"/>
		</g>
		<g transform="translate(65.712 5.209)">
			<path d="M550.561,57.508a17.935,17.935,0,0,0-6.388-12.432A19.15,19.15,0,0,0,531.1,40.859a19.635,19.635,0,0,0-14.515,7.589A22.392,22.392,0,0,0,514.3,51.86h2.377a19.264,19.264,0,0,1,1.508-2.121A17.665,17.665,0,0,1,531.2,42.9a17.175,17.175,0,0,1,11.678,3.756,15.708,15.708,0,0,1,5.66,11.026,12.562,12.562,0,0,1-3.348,9.238,11.086,11.086,0,0,1-7.909,3.871,9.409,9.409,0,0,1-7.947-4.114h-2.364a11.49,11.49,0,0,0,10.209,6.158h.128a13.173,13.173,0,0,0,9.4-4.549A14.475,14.475,0,0,0,550.561,57.508Z" transform="translate(-514.3 -40.839)"/>
		</g>
	</svg>
);

export default Logo;
