import * as React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'react-bootstrap';
import { Route, RouteComponentProps } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';

import './Breadcrumbs.styles.scss';

export interface ICrumbProps extends RouteComponentProps<any> {}
export interface IBreadcrumbsProps extends React.HTMLAttributes<HTMLElement> {}
 
const Crumb: React.SFC<ICrumbProps> = (props) => {
  const {
    match
  } = props;

  const pathTitle = match.params.path;

  return (
    <React.Fragment>
      <LinkContainer to={match.url}>
        <BreadcrumbItem active={match.isExact}>
          { pathTitle }
        </BreadcrumbItem>
      </LinkContainer>
      <Route path={`${match.url}/:path`} component={Crumb} />
    </React.Fragment>
  );
}
 
const Breadcrumbs: React.SFC<IBreadcrumbsProps> = (props) => {
  return (
    <nav {...props}>
      <Breadcrumb>
        <Route path='/:path' component={Crumb} />
      </Breadcrumb>
    </nav>
  );
}
 
export default Breadcrumbs;