import * as React from 'react';
import { Path } from '../../../utils/OnDemandApi/EdgeDevices';

import './ActivePathDiagram.scss';

export interface ActivePathProps {
	path: Path,
	position: number,
}
 
const ActivePath: React.SFC<ActivePathProps> = ({path}) => {

	const pathStatus = (path.active == true) ? (path.roundTripTime > 0 ? 'active' : 'impaired') : 'inactive';

	return (  
		<div 
			className={`path ${pathStatus}`}
			data-int-client={path.receiverInterface}
			data-int-gateway={path.senderInterface}
			data-path-cost={path.cost}
		/>
	);
}

export interface GatewayInterfaceElementProps {
	path: Path,
	position: number,
}
 
const GatewayInterfaceElement: React.SFC<GatewayInterfaceElementProps> = ({path, position}) => {

	const gatewayStatus = (path.active == true) ? (path.roundTripTime > 0 ? 'active' : 'impaired') : 'inactive';
	const gatewayLabel = (position == 0) ? 'D' : 'C';

	return (
		<div className={`interface interface--gateway ${gatewayStatus}`}>
			{ gatewayLabel }
		</div>
	);
}
 
export interface ActivePathDiagramProps {
	paths : Path[],
}

export const ActivePathDiagram: React.SFC<ActivePathDiagramProps> = ({ paths }) => {

	// Access the active client interface value.
	const activeClientInterface = paths.reduce((result, path) => path.receiverInterface, '');
	const wan1Status = (activeClientInterface == 'wan1') ? 'active' : 'inactive';
	const wan2Status = (activeClientInterface == 'wan2') ? 'active' : 'inactive';

	return (
		<article className='active-path-diagram'>
			<h4 className="diagram__title">Active Path Diagram</h4>
			<div className="diagram__canvas">
				<div className="node node--client">
					<div className={`interface interface--wan1 ${wan1Status}`}>A</div>
					<div className={`interface interface--wan2 ${wan2Status}`}>B</div>
				</div>
				<div className="node node--gateway">
					{ 
						paths.map((path, i) => (<GatewayInterfaceElement path={path} position={i} key={i} /> ))
					}
				</div>
				<div className="paths">
					{ 
						paths.map((path, i) => <ActivePath path={path} position={i} key={i} /> )
					}
				</div>
			</div>
		</article>
	)
};

export default ActivePathDiagram;
