import * as React from 'react';

import Session from '../Session/Session.component';
// import http from '../../shared/http';

import UserContext from '../../../contexts/User.context';
import AuthenticationContext from '../../../contexts/Authentication.context';
// import * as OApi from '../../../utils/OnDemandApi';
// import { UserSession as OApiSession } from '../../../utils/OnDemandApi/User';

import './LoadingSession.scss';
import Logo from '../../shared/branding/Logo.component';

const LoadingSession = () => (
	<section id='loading-session'>
		<h2>Loading</h2>
		<Logo/>
	</section>
);

class UserSession extends React.Component<{ onError: () => void }> {
	public render() {
		return (
			<AuthenticationContext.Consumer>
				{(/* { token: getToken } */) => (
					<UserContext.Consumer>
						{(/* { update } */) => (
							<Session
								loader={<LoadingSession/>}
								onError={this.props.onError}
								createSession={async () => {
									// const token = getToken();
									// if (token === null) { throw Error('No token'); }
									// const response = await http(OApi.User.session(token));
									// const result = await response.json() as OApiSession;
									// update(result);
								}}
							>
								{this.props.children}
							</Session>
						)}
					</UserContext.Consumer>
				)}
			</AuthenticationContext.Consumer>
		);
	}
}

export default UserSession;
