/**
 * Send HTTP requests using the Fetch API
 *
 * This module extends the Fetch API approach to support timeouts and HTTP Exception types.
 */
import {
	HttpBadRequest,
	HttpForbidden,
	HttpClientError,
	HttpNotFound,
	HttpUnauthorized,
	HttpServerError,
	HttpInternalServerError,
	HttpNotImplemented,
	HttpBadGateway,
	HttpServiceUnavailable,
	HttpGatewayTimeout,
	HttpNetworkAuthenticationRequired,
	HttpInsufficientStorage,
	HttpVersionNotSupported,
	HttpUnavailableForLegalReasons,
	HttpRequestHeaderFieldsTooLarge,
	HttpTooManyRequests,
	HttpPreconditionRequired,
	HttpFailedDependency,
	HttpLocked,
	HttpUnprocessableEntity,
	HttpExpectationFailed,
	HttpRequestRangeNotSatisfiable,
	HttpUnsupportedMediaType,
	HttpRequestURITooLong,
	HttpRequestEntityTooLarge,
	HttpPreconditionFailed,
	HttpLengthRequired,
	HttpGone,
	HttpConflict,
	HttpRequestTimeout,
	HttpProxyAuthenticationRequired,
	HttpNotAcceptable,
	HttpMethodNotAllowed,
	HttpPaymentRequired,
	HttpMultipleChoices,
	HttpMovedPermanently,
	HttpFound,
	HttpSeeOther,
	HttpNotModified,
	HttpUseProxy,
	HttpTemporaryRedirect,
	HttpPermanentRedirect,
	HttpRedirection
} from './HttpException';
import { NetworkException } from './NetworkException';
import { FetchTimeout } from './FetchTimeout';

/** Wraps `fetch` to provide a timeout */
const fetchTimeout = (request: Request, timeout: number): Promise<Response> =>
	new Promise((resolve, reject) => {
		const timeoutId = setTimeout(
			() => reject(new FetchTimeout('Request has timed out')),
			timeout
		);

		fetch(request)
			.then(response => {
				clearTimeout(timeoutId);
				resolve(response);
			})
			.catch(error => {
				clearTimeout(timeoutId);
				reject(error);
			});
	});

/** Checks response for an unsuccessful response code and throws corresponding exception */
const checkResponse = (response: Response): void => {
	if (!response.ok) { // Response is not 2xx
		const { status } = response;

		if (status >= 500) {
			switch (status) {
				case 500:
					throw new HttpInternalServerError(response);
				case 501:
					throw new HttpNotImplemented(response);
				case 502:
					throw new HttpBadGateway(response);
				case 503:
					throw new HttpServiceUnavailable(response);
				case 504:
					throw new HttpGatewayTimeout(response);
				case 505:
					throw new HttpVersionNotSupported(response);
				case 507:
					throw new HttpInsufficientStorage(response);
				case 511:
					throw new HttpNetworkAuthenticationRequired(response);
				default:
					throw new HttpServerError(response);
			}
		}

		if (status < 400) {
			switch (status) {
				case 300:
					throw new HttpMultipleChoices(response);
				case 301:
					throw new HttpMovedPermanently(response);
				case 302:
					throw new HttpFound(response);
				case 303:
					throw new HttpSeeOther(response);
				case 304:
					throw new HttpNotModified(response);
				case 305:
					throw new HttpUseProxy(response);
				case 307:
					throw new HttpTemporaryRedirect(response);
				case 308:
					throw new HttpPermanentRedirect(response);
				default:
					throw new HttpRedirection(response);
			}
		}

		switch (status) {
			case 400:
				throw new HttpBadRequest(response);
			case 401: // Response if user is unauthenticated, or auth token is expired
				throw new HttpUnauthorized(response);
			case 402:
				throw new HttpPaymentRequired(response);
			case 403: // Response if user is unauthorized, not permitted, to view a resoure
				throw new HttpForbidden(response);
			case 404:
				throw new HttpNotFound(response);
			case 405:
				throw new HttpMethodNotAllowed(response);
			case 406:
				throw new HttpNotAcceptable(response);
			case 407:
				throw new HttpProxyAuthenticationRequired(response);
			case 408:
				throw new HttpRequestTimeout(response);
			case 409:
				throw new HttpConflict(response);
			case 410:
				throw new HttpGone(response);
			case 411:
				throw new HttpLengthRequired(response);
			case 412:
				throw new HttpPreconditionFailed(response);
			case 413:
				throw new HttpRequestEntityTooLarge(response);
			case 414:
				throw new HttpRequestURITooLong(response);
			case 415:
				throw new HttpUnsupportedMediaType(response);
			case 416:
				throw new HttpRequestRangeNotSatisfiable(response);
			case 417:
				throw new HttpExpectationFailed(response);
			case 422:
				throw new HttpUnprocessableEntity(response);
			case 432:
				throw new HttpLocked(response);
			case 424:
				throw new HttpFailedDependency(response);
			case 428:
				throw new HttpPreconditionRequired(response);
			case 429:
				throw new HttpTooManyRequests(response);
			case 431:
				throw new HttpRequestHeaderFieldsTooLarge(response);
			case 451:
				throw new HttpUnavailableForLegalReasons(response);
			default:
				throw new HttpClientError(response);
		}
	}
};

export const http = async (request: Request, timeout: number = 90000): Promise<Response> => {
	let response: Response;
	try {
		response = await fetchTimeout(request, timeout);
	}
	catch (error) {
		if (error instanceof FetchTimeout) {
			throw error;
		}

		throw new NetworkException(error.message);
	}

	checkResponse(response);

	return response;
};

export default http;
