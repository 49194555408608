import * as React from 'react';

import { DeviceState } from '../../../utils/OnDemandApi/EdgeDevices';
import './DeviceInfo.scss';
import { Settings } from '../../shared/icons';

const formatPercentage = (ratio: number) => `${(ratio * 100).toFixed(0)}%`;
const formatRtd = (rtd: number) => `${rtd.toFixed(0)}ms`;

type PathStatus = 'active' | 'inactive' | 'down';

const pathStatus = (rtd: number): PathStatus => {
	if (rtd === -1) { return 'down'; }
	if (rtd === 0) { return 'inactive'; }
	return 'active';
};

type PathStats = {
	status: PathStatus,
	packetsReceived: number,
	packetLoss: string,
	latency: string
};

export interface DeviceInfoProps {
	device: DeviceState
}

interface DeviceInfoState {
	/** Renders detailed path information when true */
	showStats: boolean
}

export class DeviceInfo extends React.Component<DeviceInfoProps, DeviceInfoState> {
	public state = {
		showStats: false
	};

	private toggleStats = (event: React.MouseEvent) => {
		event.preventDefault();
		this.setState(prevState => ({ ...prevState, showStats: !prevState.showStats }));
	}

	private stats = (device: DeviceState) => {
		const { fullReport: {
			paths,
			clientQuality: { links: clientLinks = [] },
			gatewayQuality: { links: gatewayLinks = [] }
		} } = device;

		// For each path in the configuration, two sets of stats will be shown; one for each end of the path
		return paths.reverse().map(({ nVEInterface, sdngwInterface }, index) => {
			// Key format: `senderInterfaceName:receiverInterfaceName`
			const inKey = `${sdngwInterface}:${nVEInterface}`;
			const outKey = `${nVEInterface}:${sdngwInterface}`;

			// Path info for data received by the device
			const [ inData ] = clientLinks.filter(({ receiverInterface, senderInterface }) => (
				receiverInterface === nVEInterface && senderInterface === sdngwInterface
			)).map<PathStats>(({ packetsReceived, lossRatio, roundTripTime }) => ({
				status: pathStatus(roundTripTime),
				packetsReceived,
				packetLoss: formatPercentage(lossRatio),
				latency: formatRtd(roundTripTime)
			}));

			// Path info for data received by the gateway
			const [ outData ] = gatewayLinks.filter(({ receiverInterface, senderInterface }) => (
				receiverInterface === sdngwInterface && senderInterface === nVEInterface
			)).map<PathStats>(({ packetsReceived, lossRatio, roundTripTime }) => ({
				status: pathStatus(roundTripTime),
				packetsReceived,
				packetLoss: formatPercentage(lossRatio),
				latency: formatRtd(roundTripTime)
			}));

			return (
				<React.Fragment key={index}>
					<tr key={inKey} data-status={inData.status}>
						<th>{`${nVEInterface} P${index+1} - In`}</th>
						<td data-packets-received={inData.packetsReceived}>{inData.packetsReceived}</td>
						<td data-packet-loss={inData.packetLoss}>{inData.packetLoss}</td>
						<td data-latency={inData.latency}>{inData.latency}</td>
					</tr>
					<tr key={outKey} data-status={outData.status}>
						<th>{`${nVEInterface} P${index+1} - Out`}</th>
						<td data-packets-received={outData.packetsReceived}>{outData.packetsReceived}</td>
						<td data-packet-loss={outData.packetLoss}>{outData.packetLoss}</td>
						<td data-latency={outData.latency}>{outData.latency}</td>
					</tr>
				</React.Fragment>
			);
		});
	}

	public render() {
		const { device } = this.props;
		const { fullReport: { clientQuality: { lossRatio } } } = device;
		const totalLoss = formatPercentage(lossRatio);

		return (
			<article className='device-info'>
				<header>
					<h3>{this.state.showStats ? 'Stats' : 'Actual Loss'}</h3>
					<menu>
						<li>
							<a href='#' onClick={this.toggleStats}>
								<Settings/>
								<span>Interface Settings</span>
							</a>
						</li>
					</menu>
				</header>
				{this.state.showStats ? (
					<table>
						<thead>
							<tr>
								<td/>
								<th scope='col'>Packet</th>
								<th scope='col'>Loss</th>
								<th scope='col'>Latency</th>
							</tr>
						</thead>
						<tbody>
							{this.stats(device)}
						</tbody>
					</table>
				) : (
					<p data-total-loss={totalLoss}>{totalLoss}</p>
				)}
			</article>
		);
	}
}

export default DeviceInfo;
