import * as React from 'react';

import * as OApi from '../../../utils/OnDemandApi';
import {
	EdgeDeviceState,
	DeviceState,
	Healthcheck,
	EmsHealthcheck,
	EdgeDeviceTunnel
} from '../../../utils/OnDemandApi/EdgeDevices';

import http, {
	FetchTimeout,
	HttpException,
	HttpTooManyRequests,
	HttpServerError
} from '../../shared/http';

import AuthContext, { AuthenticationContext } from '../../../contexts/Authentication.context';

export enum PathConfiguration {
	TwoWired = 9,
	TwoWiredLTE = 10
}

export interface EdgeDeviceInfo {
	pathConfig: PathConfiguration | null,
	device: DeviceState | null,
	health: Healthcheck | null
}

export interface EdgeDeviceActions {
	injectPacketLoss: (gatewayName: string, interfaceName: string, ratio: number) => Promise<void>
	updatePathConfig: (configId: PathConfiguration) => Promise<void>
}

export interface EdgeDeviceProps {
	id: string,
	onError: (error: any) => void,
	children: (props: EdgeDeviceInfo & EdgeDeviceActions) => React.ReactNode
}

interface ComponentState extends EdgeDeviceInfo {
	polling: boolean
}

export default class EdgeDevice extends React.Component<EdgeDeviceProps, ComponentState> {
	public static contextType = AuthContext;

	private timeout: NodeJS.Timeout | undefined;

	public state = {
		polling: false,
		pathConfig: null,
		device: null,
		health: null
	};

	private getToken = () => {
		const { token: getToken, invalidate } = this.context as AuthenticationContext;

		const token = getToken();

		if (token === null) {
			invalidate();
			throw new TypeError('Your session has expired');
		}

		return token;
	}

	private fetchDevice = async (token: string, id: string) => {
		const result = await http(OApi.EdgeDevices.get(token, id));
		const { data: [ device ], meta }: EdgeDeviceState = await result.json();

		if (meta.pagination && meta.pagination.count > 1) {
			throw new TypeError('Received multiple momentary reports for a single device');
		}

		return device;
	}

	private fetchHealth = async (token: string, id: string) => {
		const result = await http(OApi.EdgeDevices.health(token, id));
		const { data }: EmsHealthcheck = await result.json();
		return data;
	}

	private checkHealth = async (retry: boolean = true) => {
		const token = this.getToken();

		const { id } = this.props;
		let health: Healthcheck | null = null;

		try {
			console.debug('Checking device health...');
			health = await this.fetchHealth(token, id);
		}
		catch (error) {
			// If there was a 5xx response, retry once
			if (error instanceof HttpServerError && retry) {
				this.checkHealth(false);
			}

			// Log error, allow health state to set to null
			console.error(error.message);
		}

		this.setState({
			health
		});
	}

	private killPolling = () => {
		this.setState({ polling: false }, () => {
			if (this.timeout) {
				clearTimeout(this.timeout);
				console.debug('No longer polling edge device');
			}
		});
	}

	private poll = () => {
		if (this.state.polling) {
			const { id } = this.props;
			let data: DeviceState | null = this.state.device;

			this.timeout = setTimeout(
				async () => {
					try {
						console.debug('Polling edge device...');
						const token = this.getToken();
						data = await this.fetchDevice(token, id);
					}
					catch (error) {
						// If a timeout occurs, continue trying to fetch
						if (error instanceof FetchTimeout) {
							console.error('Request timed out');
						}
						// If an unsuccessful repsonse is received, continue trying
						else if (error instanceof HttpServerError) {
							console.error('Server unable to handle request');
						}
						// If being rate limited, log and continue trying
						else if (error instanceof HttpTooManyRequests) {
							console.error('You are being rate limited');
						}
						// If any other HTTP exception occurs, continue trying
						else if (error instanceof HttpException) {
							console.error('An HTTP exception ocurred');
						}
						// Any other error will stop polling
						else {
							console.error('Unable to continue polling edge device');
							this.props.onError(error);
							this.killPolling();
							return;
						}
					}

					// Clear this timeout before creating the next one
					if (this.timeout) { clearTimeout(this.timeout); }
					this.setState({ device: data }, () => this.poll());
				},
				500
			);
		}
	}

	private startPolling = () => {
		this.setState({ polling: true }, () => this.poll());
	}

	private injectPacketLoss = async (gatewayName: string, interfaceName: string, ratio: number) => {
		const token = this.getToken();
		const { id } = this.props;

		const response = await http(OApi.EdgeDevices.injectTunnelPacketLoss(token, id, gatewayName, interfaceName, ratio));
		const result = await response.json();
		console.log(result);
	}

	private updatePathConfig = async (configId: PathConfiguration) => {
		const token = this.getToken();
		const { id } = this.props;

		const response = await http(OApi.EdgeDevices.updatePathConfiguration(token, id, configId));
		await response.json();
		this.setState({ pathConfig: configId });
	}

	private resetPacketLoss = async () => {
		const token = this.getToken();
		const { id } = this.props;

		console.debug('Fetching initial device state...');
		const device = await this.fetchDevice(token, id);

		console.debug('Resetting all path simulated packet loss...');
		// If any request fails, let the error be thrown. All paths must be reset before rendering.
		await Promise.all(device.fullReport.paths
			.map(({ nVEInterface, sdngwInterface }) => (
				http(OApi.EdgeDevices.injectTunnelPacketLoss(token, id, sdngwInterface, nVEInterface, 0.0))
			)));

		console.debug('All paths simulated packet loss have been reset');
	}

	private identifyConfig = async () => {
		const token = this.getToken();
		const { id } = this.props;

		const response = await http(OApi.EdgeDevices.tunnel(token, id));
		const { data: [{ pathConfigVersion }] }: EdgeDeviceTunnel = await response.json();

		if (!pathConfigVersion) {
			throw new TypeError('Could not identify edge device configuration');
		}

		return pathConfigVersion;
	}

	private resetConfig = async () => {
		let pathConfig = await this.identifyConfig();
		console.debug(`Edge device currently set to use ${
			`PathConfiguration.${PathConfiguration[pathConfig]}` || `path configuration ID ${pathConfig}`
		}`);

		// If config is not supported, reset to TwoWired
		if (!PathConfiguration[pathConfig]) {
			console.debug('Resetting device configuration to Two Wired');
			const token = this.getToken();
			const { id } = this.props;
			await http(OApi.EdgeDevices.updatePathConfiguration(token, id, PathConfiguration.TwoWired));
			pathConfig = PathConfiguration.TwoWired;
		}

		this.setState({ pathConfig });
	}

	public async componentDidMount() {
		const { id, onError } = this.props;
		if (!id || id === '') {
			throw new TypeError('Edge Device CPU ID is required');
		}

		// Health check unnecessary for device, and causes longer load times, so it is disabled
		// await this.checkHealth();

		try {
			await this.resetPacketLoss();
			await this.resetConfig();

			console.debug('Starting device polling...');
			await this.startPolling();
		}
		catch (error) {
			onError(error);
		}
	}

	public componentWillUnmount() {
		this.killPolling();
	}

	public componentDidCatch(error: any) {
		this.props.onError(error);
	}

	public render() {
		return this.props.children({
			...this.state,
			injectPacketLoss: this.injectPacketLoss,
			updatePathConfig: this.updatePathConfig
		});
	}
}
