/**
 * Runtime configuration available from server-side environment variables
 * or global variables added to the browser window. For create-react-app,
 * environment variables must be prefixed with `REACT_APP_` to be accessible.
 */
export const runtimeConfig = {
	OAPI_BASE_URL:			process.env.REACT_APP_OAPI_BASE_URL,
	OAPI_CLIENT_ID:			process.env.REACT_APP_OAPI_CLIENT_ID,
	OAPI_CLIENT_SECRET:	process.env.REACT_APP_OAPI_CLIENT_SECRET
};

export default runtimeConfig;
