import * as React from 'react';
import Context, {
	UserContext,
	defaultValue
} from '../../../contexts/User.context';
import { UserSession } from '../../../utils/OnDemandApi/User';

export default class User extends React.Component<{}, UserContext> {
	public state = defaultValue;

	private get fullName(): string | null {
		return 'Wes Rogers';

		// if (this.state.session === null) { return null; }

		// const { data: [ { nameFirst, nameLast } ] } = this.state.session;

		// return `${nameFirst} ${nameLast}`;
	}

	private update = (session: UserSession | null) => this.setState({ session });

	public render() {
		return (
			<Context.Provider value={{ ...this.state, fullName: this.fullName, update: this.update }}>
				{this.props.children}
			</Context.Provider>
		);
	}
}
