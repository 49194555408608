import * as React from 'react';
import AuthContext, {
	AuthenticationContext,
	defaultValue
} from '../../../contexts/Authentication.context';

export interface AuthenticationToken {
	jwt: string,
	expiry: number
}

/**
 * Reads `localStorage` after mounting for pre-existing OAPI authentication token
 */
export default class Authentication extends React.Component<{}, AuthenticationContext> {
	constructor(props: {}) {
		super(props);

		this.state = {
			...defaultValue,
			authenticated: defaultValue.token() !== null ? true : false,
			invalidate: () => this.setState({ authenticated: false })
		};
	}

	// NOTE: Checking storage on component mount doesn't work because state update
	// is asynchronus and route redirection occurs before the state is updated.

	public render() {
		return (
			<AuthContext.Provider value={this.state}>
				{this.props.children}
			</AuthContext.Provider>
		);
	}
}
