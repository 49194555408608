import * as React from 'react';
import Downshift from 'downshift';

import './Select.scss';

export interface SelectOption {
	label: string,
	value: any
}

export interface SelectProps {
	label: string,
	selected: any | null,
	options: SelectOption[],
	emptyValue: string,
	defaultSelection?: any,
	onChange: (value: any) => void
}

export const Select: React.SFC<SelectProps> = ({
	label,
	selected,
	options,
	emptyValue,
	defaultSelection = null,
	onChange,
}) => (
	<Downshift
		itemToString={item => (item ? item.toString() : '')}
		initialInputValue={defaultSelection.toString()}
		selectedItem={selected}
		onChange={onChange}
	>
		{({
			inputValue,
			getItemProps,
			getLabelProps,
			getMenuProps,
			openMenu,
			isOpen
		}) => (
			<div className='select' onClick={() => openMenu()}>
				<label {...getLabelProps()}>{label}</label>
				<span>
					{(options.find(option => option.value.toString() === inputValue) || { label: emptyValue }).label}
				</span>
				{isOpen && (
					<ul {...getMenuProps()}>
						{options.map((option, index) => (
							<li
								{...getItemProps({
									key: option.value,
									index,
									item: option.value
								})}
							>
								{option.label}
							</li>
						))}
					</ul>
				)}
			</div>
		)}
	</Downshift>
);

export default Select;
