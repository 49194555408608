import * as React from 'react';
import { Glyphicon, MenuItem, Nav, NavDropdown, NavItem } from 'react-bootstrap';

import './UserNavigation.styles.scss';

import User from '../../../../contexts/User.context';

const UserNavigation: React.SFC = () => (
	<User.Consumer>
		{({ fullName }) => (
			<Nav pullRight={true}>
				<NavItem eventKey={1} href='#'>
					<Glyphicon glyph='bell'/>
					<span className='sr-only'>Notifications</span>
				</NavItem>
				<NavItem eventKey={2} href='#'>
					<Glyphicon glyph='envelope'/>
					<span className='sr-only'>Messages</span>
				</NavItem>
				<NavDropdown eventKey={3} title={fullName} id='basic-nav-dropdown'>
					<MenuItem eventKey={3.1}>Profile</MenuItem>
					<MenuItem eventKey={3.2}>Settings</MenuItem>
					<MenuItem divider={true}/>
					<MenuItem eventKey={3.3}>Logout</MenuItem>
				</NavDropdown>
			</Nav>
		)}
	</User.Consumer>
);

export default UserNavigation;
