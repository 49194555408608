/**
 * Based on the exceptions used in the webob Python library.
 *
 * Use of these error types allows for improved error handling logic based on types.
 */

export class HttpException extends Error {
	public static code: number = 500;
	public static title: string = 'Internal Server Error';
	public static explination: string = '';
	public static emptyBody: boolean = false;

	public response: Response;

	constructor(
		response: Response,
		message?: string
	) {
		super(message);
		this.response = response;
	}
}

export class HttpError extends HttpException {}

export class HttpRedirection extends HttpException {}

// 2xx

export class HttpSuccess extends HttpException {
	public static code = 200;
	public static title = 'Ok';
}

export class HttpOk extends HttpSuccess {}

export class HttpCreated extends HttpSuccess {
	public static code = 201;
	public static title = 'Created';
}

export class HttpAccepted extends HttpSuccess {
	public static code = 202;
	public static title = 'Accepted';
	public static explination = 'The request is accepted for processing';
}

export class HttpNonAuthoritativeInformation extends HttpSuccess {
	public static code = 203;
	public static title = 'Non-Authoritative Information';
}

export class HttpNoContent extends HttpSuccess {
	public static code = 204;
	public static title = 'No Content';
	public static emptyBody = true;
}

export class HttpResetContent extends HttpSuccess {
	public static code = 205;
	public static title = 'Reset Content';
	public static emptyBody = true;
}

export class HttpPartialContent extends HttpSuccess {
	public static code = 206;
	public static title = 'Partial Content';
}

// 3xx

export class HttpMultipleChoices extends HttpRedirection {
	public static code = 300;
	public static title = 'Multiple Choices';
}

export class HttpMovedPermanently extends HttpRedirection {
	public static code = 301;
	public static title = 'Moved Permanently';
}

export class HttpFound extends HttpRedirection {
	public static code = 302;
	public static title = 'Found';
	public static explination = 'The resource was found';
}

export class HttpSeeOther extends HttpRedirection {
	public static code = 303;
	public static title = 'See Other';
}

export class HttpNotModified extends HttpRedirection {
	public static code = 304;
	public static title = 'Not Modified';
	public static emptyBody = true;
}

export class HttpUseProxy extends HttpRedirection {
	public static code = 305;
	public static title = 'Use Proxy';
	public static explination = 'The resource must be accessed through a proxy';
}

export class HttpTemporaryRedirect extends HttpRedirection {
	public static code = 307;
	public static title = 'Temporary Redirect';
}

export class HttpPermanentRedirect extends HttpRedirection {
	public static code = 308;
	public static title = 'Permanent Redirect';
}

// 4xx

export class HttpClientError extends HttpError {
	public static code = 400;
	public static title = 'Bad Request';
	public static explination =
		'The server could not comply with the request since \
		it is either malformed or otherwise incorrect.';
}

export class HttpBadRequest extends HttpClientError {}

export class HttpUnauthorized extends HttpClientError {
	public static code = 401;
	public static title = 'Unauthorized';
	public static explination =
		'This server could not verify that you are authorized to \
		access the document you requested. Either you supplied the \
		wrong credentials (e.g., bad password), or your browser \
		does not understand how to supply the credentials required.';
}

export class HttpPaymentRequired extends HttpClientError {
	public static code = 402;
	public static title = 'Payment Required';
	public static explination = 'Access was deined for financial reasons.';
}

export class HttpForbidden extends HttpClientError {
	public static code = 403;
	public static title = 'Forbidden';
	public static explination = 'Access was denied to this resource.';
}

export class HttpNotFound extends HttpClientError {
	public static code = 404;
	public static title = 'Not Found';
	public static explination = 'The resource could not be found.';
}

export class HttpMethodNotAllowed extends HttpClientError {
	public static code = 405;
	public static title = 'Method Not Allowed';
	public static explination = 'The meothod is not allowed for this resource.';
}

export class HttpNotAcceptable extends HttpClientError {
	public static code = 406;
	public static title = 'Not Acceptable';
	public static explination =
		'The resoure could not be generated that was acceptable to your browser.';
}

export class HttpProxyAuthenticationRequired extends HttpClientError {
	public static code = 407;
	public static title = 'Proxy Authentication Required';
	public static explination = 'Authentication with a local proxy is needed.';
}

export class HttpRequestTimeout extends HttpClientError {
	public static code = 408;
	public static title = 'Request Timeout';
	public static explination =
		'The server has waited too long for the request to be sent by the client.';
}

export class HttpConflict extends HttpClientError {
	public static code = 409;
	public static title = 'Conflict';
	public static explination =
		'There was a conflict when trying to complete your request.';
}

export class HttpGone extends HttpClientError {
	public static code = 410;
	public static title = 'Gone';
	public static explination =
		'The resource is no longer available. No forwarding address is given.';
}

export class HttpLengthRequired extends HttpClientError {
	public static code = 411;
	public static title = 'Length Required';
	public static explination = 'Content-Length header required.';
}

export class HttpPreconditionFailed extends HttpClientError {
	public static code = 412;
	public static title = 'Precondition Failed';
	public static explination = 'Request precondition failed.';
}

export class HttpRequestEntityTooLarge extends HttpClientError {
	public static code = 413;
	public static title = 'Request Entity Too Large';
	public static explination =
		'The body of your request was too large for this server.';
}

export class HttpRequestURITooLong extends HttpClientError {
	public static code = 414;
	public static title = 'Request-URI Too Long';
	public static explination = 'The request URI was too long for this server.';
}

export class HttpUnsupportedMediaType extends HttpClientError {
	public static code = 415;
	public static title = 'Unsupported Media Type';
	public static explination =
		'The request media type is not supported by this server.';
}

export class HttpRequestRangeNotSatisfiable extends HttpClientError {
	public static code = 416;
	public static title = 'Request Range Not Satisfiable';
	public static explination = 'The Range requested is not available.';
}

export class HttpExpectationFailed extends HttpClientError {
	public static code = 417;
	public static title = 'Expectation Failed';
	public static explination = 'Expectation failed.';
}

export class HttpUnprocessableEntity extends HttpClientError {
	public static code = 422;
	public static title = 'UnprocessableEntity';
	public static explination = 'Unable to process the contained instructions.';
}

export class HttpLocked extends HttpClientError {
	public static code = 423;
	public static title = 'Locked';
	public static explination = 'The resource is locked.';
}

export class HttpFailedDependency extends HttpClientError {
	public static code = 424;
	public static title = 'Failed Dependency';
	public static explination =
		'The method could not be performed because the requested \
		action depended on another action and that action failed.';
}

export class HttpPreconditionRequired extends HttpClientError {
	public static code = 428;
	public static title = 'Precondition Required';
	public static explination = 'The request is requied to be conditional.';
}

export class HttpTooManyRequests extends HttpClientError {
	public static code = 429;
	public static title = 'Too Many Requests';
	public static explination =
		'The client has sent too many requests in a given amount of time.';
}

export class HttpRequestHeaderFieldsTooLarge extends HttpClientError {
	public static code = 431;
	public static title = 'Request Header Fields Too Large';
	public static explination = 'The request header fields were too large.';
}

export class HttpUnavailableForLegalReasons extends HttpClientError {
	public static code = 451;
	public static title = 'Unavailable For Legal Reasons';
	public static explination = 'The resource is not available due to legal reasons.';
}

// 5xx

export class HttpServerError extends HttpError {
	public static code = 500;
	public static title = 'Internal Server Error';
	public static explination =
		'The server has either erred or is incapable of performing \
		the requested operation.';
}

export class HttpInternalServerError extends HttpServerError {}

export class HttpNotImplemented extends HttpServerError {
	public static code = 501;
	public static title = 'Not Implemented';
	public static explination =
		'The requested meothod is not implemented for this server.';
}

export class HttpBadGateway extends HttpServerError {
	public static code = 502;
	public static title = 'Bad Gateway';
	public static explination = 'Bad gateway.';
}

export class HttpServiceUnavailable extends HttpServerError {
	public static code = 503;
	public static title = 'Service Unavailable';
	public static explination =
		'The server is currently unavailable. Please try again at a later time.';
}

export class HttpGatewayTimeout extends HttpServerError {
	public static code = 504;
	public static title = 'Gateway Timeout';
	public static explination = 'The gateway has timed out.';
}

export class HttpVersionNotSupported extends HttpServerError {
	public static code = 505;
	public static title = 'HTTP Version Not Supported';
	public static explination = 'The HTTP version is not supported.';
}

export class HttpInsufficientStorage extends HttpServerError {
	public static code = 507;
	public static title = 'Insufficient Storage';
	public static explination = 'There was not enough space to save the resource.';
}

export class HttpNetworkAuthenticationRequired extends HttpServerError {
	public static code = 511;
	public static title = 'Network Authentication Required';
	public static explination = 'Network authentication is required.';
}
