import * as React from 'react';
import { Badge } from 'react-bootstrap';
import { Path } from '../../../utils/OnDemandApi/EdgeDevices';
import { Settings, Lte } from '../../shared/icons';
import { ActivePathDiagram } from './ActivePathDiagram.component';
import './InterfaceStatus.scss';

const formatPercentage = (ratio: number) => `${(ratio * 100).toFixed(0)}%`;
const formatRtd = (rtd: number) => `${rtd.toFixed(0)}ms`;

export interface DeviceInterface {
	/** Name of the WAN interface */
	name: string,
	/** IP Address */
	ip: string,
	/** Port number */
	port: number,
	/** Paths connected to the interface */
	paths: Path[]
}

export type InterfaceProps = DeviceInterface;

interface InterfaceState {
	/** Renders interface information when true */
	showInfo: boolean
}

export class Interface extends React.Component<InterfaceProps, InterfaceState> {
	public state = {
		showInfo: false
	};

	
	/**
	 * Returns true if all paths are LTE
	 * as designated by the path cost value.
	 */
	private get isLte(): boolean {
		const ltePathCostFloor = 5;
		return !(this.props.paths.filter(path => path.cost <= ltePathCostFloor).length > 0);
	}

	private get status(): 'active' | 'inactive' | 'down' {

		// Inactive - All paths are inactive.
		// Active - All paths are active.
		// Down - All paths are active and RTD is less than 0.

		const activePaths = this.props.paths.filter(path => path.active);
		if (!(activePaths.length > 0)) {
			return 'inactive';
		}

		const reachablePaths = this.props.paths.filter(path => path.roundTripTime > -1 && path.active);
		if (!(reachablePaths.length > 0)) {
			return 'down';
		}

		return 'active';
	}

	/** The total packets received by all paths using the interface */
	private get packetsReceived(): number {
		return this.props.paths.reduce((sum, path) => sum + path.packetsReceived, 0);
	}

	/** The average packet loss ratio for all paths using the interface; 0. - 1. */
	private get packetLossRatio(): number {
		const { paths } = this.props;
		const sum = paths.reduce((sum, path) => sum + path.lossRatio, 0);
		return sum / paths.length;
	}

	/** The average latency for all paths using the interface */
	private get latency(): number {
		const activePaths = this.props.paths.filter(path => path.active);
		if (!(activePaths.length > 0)){
			return 0;
		}

		const usablePaths = this.props.paths.filter(path => (path.roundTripTime !== -1 && path.active === true));
		if (!(usablePaths.length > 0)) {
			return -1;
		}

		const sum = usablePaths.reduce((sum, path) => sum + path.roundTripTime, 0);
		return sum / usablePaths.length;
	}

	private toggleInfo = (event: React.MouseEvent) => {
		event.preventDefault();
		this.setState(prevState => ({ ...prevState, showInfo: !prevState.showInfo }));
	}

	public render() {
		const { name, ip, paths } = this.props;
		const interfacePacektsReceived = this.packetsReceived;
		const interfacePacketLoss = formatPercentage(this.packetLossRatio);
		const interfaceLatency = formatRtd(this.latency);

		return (
			<article className='interface' data-status={this.status}>
				<header>
					{this.isLte ? (
						<h3 data-interface-type='lte'>
							<span>LTE</span>
							<Lte/>
							<Badge className={`interface__status ${this.status}`}>{this.status}</Badge>
						</h3>
					) : (
						<h3 data-interface-type='wan'>
							{name}
							<Badge className={`interface__status ${this.status}`}>{this.status}</Badge>
						</h3>
					)}
					<menu>
						<li>
							<a href='#' onClick={this.toggleInfo}>
								<Settings/>
								<span>Interface Settings</span>
							</a>
						</li>
					</menu>
				</header>
				{this.state.showInfo ? (
					<dl className='settings'>
						<dt>{name} IP Address</dt>
						<dd data-ip-address={ip}>{ip}</dd>
						{paths.map((path, index) => (
							<React.Fragment key={index}>
								<dt>{path.senderInterface} IP Address</dt>
								<dd data-ip-address={path.senderIP}>{path.senderIP}</dd>
							</React.Fragment>
						))}
					</dl>
				) : (
					<React.Fragment>
						<ActivePathDiagram paths={paths} />
						<dl className='metrics'>
							<div>
								<dt data-key='packets-received'>Packets</dt>
								<dd data-packets-received={interfacePacektsReceived}>{interfacePacektsReceived}</dd>
							</div>
							<div>
								<dt data-key='packet-loss'>Loss</dt>
								<dd data-packets-loss={interfacePacketLoss}>{interfacePacketLoss}</dd>
							</div>
							<div>
								<dt data-key='latency'>Latency</dt>
								<dd data-latency={interfaceLatency}>{interfaceLatency}</dd>
							</div>
						</dl>
					</React.Fragment>
				)}
			</article>
		);
	}
}

export default Interface;
