import * as React from 'react';
import { Grid, Col, Row } from 'react-bootstrap';

import { Breadcrumbs } from '../../Navigation';

import './Page.styles.scss';

export interface IPageProps {
  title: string,
}
 
const Page: React.SFC<IPageProps> = (props) => {
  const {
    children,
    title,
  } = props;

  // Parses a string into a format to be used as a className.
  const formatPageClassname = (str: string) => {
    return  str.replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) => {
              return letter.toLowerCase();
            })
            .replace(/\s+/g, '_')
            .replace(/[^a-zA-Z0-9\_]/g,'');
  };

  // Defines a custom page className from the title prop.
  const pageClassName = formatPageClassname(title);

  return ( 
    <Grid
      className={`page page--${pageClassName}`}
      componentClass="main"
      fluid={true}
    >
      <Row>     
        <Col md={12}>
          <header>
            <h2 className="page__title">{title}</h2>
            <Breadcrumbs className="page__breadcrumbs" />
          </header>
        </Col>
      </Row>
      <Row>
        <article className="page__content">
          {children}
        </article>
      </Row>  
    </Grid>
   );
}
 
export default Page;