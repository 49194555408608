import * as React from 'react';
import { UserSession } from '../utils/OnDemandApi/User';

export interface UserContext {
	session: UserSession | null,
	fullName: string | null,
	update: (session: UserSession | null) => void
}

export const defaultValue: Readonly<UserContext> = Object.freeze({
	session: null,
	fullName: null,
	update: () => { /* noop */ }
});

export default React.createContext(defaultValue);
