import * as React from 'react';

export interface AuthenticationToken {
	jwt: string,
	expiry: number
}

export interface AuthenticationContext {
	authenticated: boolean,
	invalidate: () => void,
	token: () => string | null
}

/**
 * The `authenticated` value is only updated when the token is accessed. When the token is missing or
 * found to be invalid the component using the context must handle the redirection.
 */
export const defaultValue: Readonly<AuthenticationContext> = Object.freeze({
	authenticated: false,

	// tslint:disable-next-line:no-empty
	invalidate: () => {},

	token: () => {
		console.debug('Reading URL for authentication token...');
		const params = new URLSearchParams(window.location.search);
		const jwt = params.get('token');
		// const expiry = params.get('expiry');

		if (jwt === null) {
			console.debug('Authentication token is missing');
			return null;
		}

		// if (expiry === null) {
		// 	console.debug('Authentication token expiry is missing');
		// 	return null;
		// }

		// // Expiry is in seconds, Date.now() outputs milliseconds
		// const hasExpired = (parseInt(expiry, 10) * 1000) <= Date.now();

		// if (hasExpired) {
		// 	console.debug('Authentication token is expired');
		// 	return null;
		// }

		return jwt;
	}
});

export default React.createContext(defaultValue);
