import * as React from 'react';
import { Page } from '..';

export interface IWithPageOwnProps  {
  title: string,
}

export interface IWithPageInjectedProps  {
  title: string,
}

const withPage = <P extends IWithPageOwnProps> (
  WrappedComponent: React.ComponentType<P & IWithPageInjectedProps>
) => class WithPage extends React.Component<
    P & IWithPageInjectedProps
  > {
  public render() {
    const {
      title
    } = this.props;
    
    return (
      <Page title={title}>
        <WrappedComponent {...this.props} />
      </Page>
    );
  }
};

export default withPage;