import * as React from 'react';
import { NavLink, RouteComponentProps } from 'react-router-dom';

import './NotFound.scss';

export const NotFound: React.SFC<RouteComponentProps> = ({ location }) => (
	<main id='not-found'>
		<h2>Sorry, we can't find that page</h2>
		<NavLink to={{ pathname: '/', search: location.search }}>Take me back</NavLink>
	</main>
);

export default NotFound;
