import * as React from 'react';

import './Button.scss';

export const Button: React.SFC<React.HTMLAttributes<HTMLButtonElement> > = ({
	children,
	...rest
}) => (
	<button {...rest}>{children}</button>
);

export default Button;
