import * as React from 'react';

import './Layout.styles.scss';

import { PrimaryNavigation } from '../../Navigation';
import Header from '../../Header/Header.component';

export interface LayoutProps extends React.HTMLAttributes<HTMLElement> {}

const Layout: React.SFC<LayoutProps> = ({
	children,
	...rest
}) => (
	<div className='layout layout--default' {...rest}>
		<PrimaryNavigation />
		<section className='layout__main'>
			<Header />
			{children}
		</section>
	</div>
);

export default Layout;
