import * as React from 'react';

/** The message type for a `Notification` */
export type NotificationType = 'info' | 'warn' | 'error';

/** A short message to inform the user */
export interface Notification {
	readonly type: NotificationType,
	readonly title: string,
	readonly message: string,
	readonly hint?: string
}

export interface NotificationContext {
	visible: boolean,
	notifications: Notification[],
	show: () => void,
	add: (notification: Notification) => void,
	close: () => void,
	clear: () => void
}

const noop = () => { /* noop */ };

export const defaultValue: Readonly<NotificationContext> = Object.freeze({
	visible: false,
	notifications: [],
	add: noop,
	show: noop,
	close: noop,
	clear: noop
});

export default React.createContext(defaultValue);
