import * as React from 'react';
import { Alert, Button } from 'react-bootstrap';

import Context, {
	NotificationContext,
	Notification as NotificationType
} from '../../../contexts/Notification.context';

import './Notification.scss';

const lookup = Object.freeze({
	info: 'info',
	warn: 'warning',
	error: 'danger'
});

export default class Notification extends React.Component<NotificationType> {
	public static contextType = Context;

	public render() {
		const { notifications: [ latest ], close } = this.context as NotificationContext;
		const { type, title, message } = latest;

		return (
			<Alert bsStyle={lookup[type]} className='notification'>
				<h4>{title}</h4>
				{message && <p>{message}</p>}
				<footer>
					<Button bsSize='small' onClick={() => close()}>Close</Button>
				</footer>
			</Alert>
		);
	}
}
